:root {
  /* Additional Colors */
  --color-yellow-50: #ffdc86;
  --color-yellow-400: #ffb80c;
  --color-blue-classic-50: #8c969d;
  --color-blue-classic-400: #263a48;
  --color-blue-classic-600: #1c272f;
  --color-blue-classic-800: #5e6d78;
  --color-blue-550: #0285cc;
  --color-blue-700: #75c2fd;
  --color-pink-400: #fac9d1;
  --color-pink-600: #f694aa;
  --color-pink-800: #cabfc1;
  --color-green-200: #4db375;
  --color-green-400: #20a052;
  --color-green-600: #1c8c47;
  --color-orange-400: #ef853f;
  --color-orange-600: #db7a39;
  --color-red-400: #d54d4d;
  --color-red-600: #bc4848;
  --color-blue-grey-100: #f0f1f5;
  --color-blue-grey-200: #e1e6ea;
  --color-grey-100: #dcdcdc;
  --color-grey-200: #c2c2c2;
  --color-grey-400: #7f7f7f;
  --color-grey-800: #626262;
  --color-carbon-300: #525252;
  --color-carbon-400: #424242;
  --color-carbon-800: #303030;
  --color-white: #fff;
  --color-emerald-400: #51ad95;
  --color-emerald-500: #519995;
  --color-emerald-800: #3f7c79;

  /* Color settings */
  --background: #FFFFFF;
  --header-background: var(--color-yellow-400);
  --header-font: var(--color-blue-classic-400);
  --text-font: #666666;
  --label-background: #14ABB2;
  --label-font: #FFFFFF;
  --market-link-open-color: var(--color-carbon-800);
  --market-link-open-background: #dfdfdf;
  --market-link-active: #14ABB2;
  --mod-title-desc-color: #000000;
}

main {
  min-height: 35rem;
}

header {
  background-color: var(--header-background);
  color: var(--header-font);
  min-height: 4rem;
}

header h3 {
  font-weight: bold;
}

header h5 {
  display: inline-block;
  padding-top: 2rem;
  margin-left: 2rem;
}

header h6 {
  display: inline-block;
  padding-top: 2rem;
  margin-left: 2rem;
}

header img {
  float: left;
  height: 80px;
  margin: 0.2rem 0rem;
}

footer {
  text-align: center;
  font-size: 1rem;
  background-color: var(--header-background);
  color: var(--label-font);
  min-height: 2rem;
}

.nolink {
  color: var(--header-font);
  text-decoration: none;
}

.nolink:hover {
  color: var(--header-font);
  text-decoration: none;
}

.nav-link-padding {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.nav-tabs .nav-link {
  margin-left: 0.2rem;
  padding: 0.1rem 0.4rem;
  color: var(---color-carbon-800);
  background-color: var(--color-grey-200);
}

.nav-tabs .nav-link.active {
  color: var(--color-white);
  background-color: var(--color-carbon-800);
}

.page-row {
  margin-bottom: 0.5rem;
}

.form-input {
  display: block;
  margin: 0.5rem 0rem;
}

.form-control {
  padding: 0.15rem 0.75rem;
}

.form-label {
  margin-bottom: 0.1rem;
}

.page-header {
  background-color: var(--color-carbon-800);
  color: Var(--color-grey-100);
  font-weight: 500;
  padding: 0.2rem 1rem;
  margin-bottom: 1rem;
  border-radius: .2rem;
}

.sub-header {
  background-color: var(--color-grey-400);
  color: Var(--color-yellow-400);
  font-weight: 500;
  padding: 0.1rem 1rem;
  margin-bottom: 1rem;
  border-radius: .2rem;
}

.sub-header.dashboard {
  width: 100%
}

.col-header {
  background-color: var(--color-carbon-800);
  color: Var(--color-grey-100);
  padding: 0.1rem 0.4rem;
  margin-bottom: 0.5rem;
  border-radius: .2rem;
}

.col-header.right {
  padding-left: 0.6em;
}

.profile-hr {
  margin: 0.5rem 0rem;
}

.market-header {
  background-color: var(--bs-body-bg);
  color: var(--color-blue-classic-400);
  font-weight: bold;
  padding: 0.2rem 1rem;
  margin-bottom: 1rem;
  margin-left: 0;
  margin-right: 0;
  border-radius: .2rem;
  border-color: var(--color-grey-100);
  border-style: solid;
  border-width: 0.1rem;
}

.dashboard-table {
  font-size: 0.9rem;
}

.results-table {
  font-size: 0.9rem;
}

.results-table td {
  padding-left: 0rem;
  padding-right: 0rem;
}

.runner {
  font-size: 0.8rem;
}

.api-btn {
  width: 4.2rem;
}

.toolbar-btn {
  width: 7rem;
}

.marketbackcol {
  background-color: var(--color-blue-700);
}

.marketlaycol {
  background-color: var(--color-pink-600);
}

.marketclosed {
  color: var(--color-blue-classic-800);
}

.marketlink {
  font-size: 0.8rem;
  font-weight: 500;
  text-decoration: none;
  padding: 0.2rem 0.4rem;
  border-radius: 0.3rem;
}

.marketlink.excluded {
  color: var(--market-link-color);
  background-color: var(--market-link-open-background);
  opacity: 50%;
}

.marketlink.open {
  color: var(--market-link-color);
  background-color: var(--market-link-open-background);
}

.marketlink.now {
  color: var(--color-white);
  background-color: #198754;
}

.marketlink.suspended {
  color: var(--color-white);
  background-color: Var(--color-yellow-400);
}

.marketlink.closed {
  text-decoration: line-through;
  color: var(--market-link-color);
  background-color: var(--market-link-open-background);
}

.wallet-display {
  font-weight: bold;
}

.card-body {
  padding: 0.3rem;
}

.card-label {
  font-weight: 500;
}

.card .badge {
  border-radius: 0.2rem;
  margin-bottom: 0rem;
}


.system-message {
  border-color: #ced4da;
  border-width: 1px;
  border-style: solid;
  padding-left: 0.3rem;
  font-weight: 500;
}

.card textarea {
  font-weight: normal;
  font-size: 0.8rem;
  resize: none;
  border-color: #FFFFFF;
}

.system-info-badge {
  border-radius: 0rem;
}

.system-info-badge-msg {
  border-radius: 0rem;
  margin-bottom: 0rem;
}

.system-info {
  font-size: 0.7rem;
  margin-bottom: 0.5rem
}